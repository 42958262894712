<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Ventas / Listado de Proformas</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Detalle</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12 border-2">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h2>
        <strong>PROFORMA # {{ proforma.numero }}</strong>
      </h2>
    </div>
    <div
      class="grid border-bottom-3"
      style="
        background-color: #f8f9fa;
        border-radius: 5px;
        border: 1px solid black;
      "
    >
      <div class="col-6">
        <p class="mb-0">
          <strong>CLIENTE: </strong>
          <span>{{ proforma.cliente }}</span>
        </p>
        <p class="mb-0">
          <strong>V&Aacute;LIDO HASTA: </strong>
          <span>{{ proforma.valido_hasta ?? "No definido" }}</span>
        </p>
      </div>
      <div class="col-6">
        <p class="mb-0">
          <strong>FECHA DE REGISTRO: </strong>
          <span>{{ proforma.fecha }}</span>
        </p>

        <p class="mb-0">
          <strong>USUARIO: </strong>
          <span>{{ proforma.vendedor }}</span>
        </p>
      </div>
    </div>
    <div class="grid mt-4">
      <DataTable
        ref="ventas"
        :value="productos"
        class="col-12 p-datatable-sm"
        showGridlines
        style="
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          line-height: 1.5;
        "
      >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="codigo_interno" header="CODIGO INTERNO">
          {{ producto.codigo_interno }}
        </Column>
        <Column field="pivot.cantidad" header="CANTIDAD">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.cantidad) }}
          </template>
        </Column>
        <Column field="fabrica_nombre" header="LABORATORIO">
          {{ producto.fabrica_nombre ?? "-" }}
        </Column>
        <Column
          field="descripcion"
          header="PRODUCTO/DESCRIPCION"
          style="font-weight: bold"
        >
          {{ producto.descripcion }}
        </Column>
        <Column
          field="pivot.fecha_vence"
          header="FECHA VENC."
          style="text-align: center"
        >
          <template #body="slotProps">
            {{
              slotProps.data.pivot.fecha_vence == "1905-03-14"
                ? "-"
                : slotProps.data.pivot.fecha_vence
            }}
          </template>
        </Column>
        <Column field="pivot.lote" header="LOTE" style="text-align: center">
          <template #body="slotProps">
            {{ slotProps.data.pivot.lote ?? "-" }}
          </template>
        </Column>
        <Column
          field="pivot.precio"
          header="PRECIO UNIT."
          style="text-align: right"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.precio) }}
          </template>
        </Column>
        <Column
          field="pivot.descuento_neto"
          header="DESCUENTO"
          style="text-align: right"
        >
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(slotProps.data.pivot.descuento_neto)
            }}
          </template>
        </Column>
        <Column header="SUBTOTAL" style="text-align: right">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.pivot.subtotal -
                  slotProps.data.pivot.descuento_neto
              )
            }}
          </template>
        </Column>
        <template #footer>
          <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>SUBTOTAL:</strong>
              {{ convertirNumeroGermanicFormat(subtotal) }}
            </h5>
          </div>
          <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>DESCUENTO:</strong>
              {{ convertirNumeroGermanicFormat(descuento) }}
            </h5>
          </div>
          <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>TOTAL PROFORMA:</strong>
              {{ convertirNumeroGermanicFormat(proforma.total) }}
            </h5>
          </div>
        </template>
      </DataTable>
    </div>
    <div class="grid mt-2 justify-content-end">
      <Button @click="goBack" class="p-button-lg p-button-primary">
        <i class="pi pi-arrow-circle-left mr-1"></i>
        &nbsp;ATR&Aacute;S
      </Button>
      <Button
        label="IMPRIMIR"
        icon="pi pi-file-pdf"
        class="p-button-danger ml-2 p-button-lg"
        v-tooltip.top="'Imprimir PDF'"
        @click="imprimirVenta(proforma.id)"
        :disabled="imprimiendo"
        :loading="imprimiendo"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import ProformaService from "@/service/ProformaService";
import VentaService from "@/service/VentaService";

export default {
  data() {
    return {
      proforma: {},
      productos: [],
      proforma_id: this.$route.params.id,
      imprimiendo: false,
    };
  },
  proformaService: null,
  ventaService: null,
  created() {
    this.proformaService = new ProformaService();
    this.ventaService = new VentaService();
  },
  mounted() {
    this.cargarProforma();
  },
  computed: {
    subtotal() {
      let subtotal = 0;
      this.productos.forEach((producto) => {
        subtotal += producto.pivot.precio * producto.pivot.cantidad;
      });
      return subtotal;
    },
    descuento() {
      let descuento_neto = 0;
      this.productos.forEach((producto) => {
        descuento_neto += parseFloat(producto.pivot.descuento_neto);
      });
      return descuento_neto;
    },
  },
  methods: {
    cargarProforma() {
      this.proformaService.buscarProforma(this.proforma_id).then((response) => {
        this.proforma = response.proforma || {};
        this.productos = response.proforma.detalles || [];
      });
    },
    imprimirVenta(id) {
      if (this.imprimiendo) {
        return;
      }
      this.imprimiendo = true;
      this.ventaService.imprimirVentaProforma(id).then(() => {
        this.imprimiendo = false;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
